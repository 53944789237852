import React, { Component } from "react";
import { Switch, Route, Redirect, Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import Select from "react-select";

import * as actions from "./../../Redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPen,
  faPlay,
  faPowerOff,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import api from "../../Utils/Api";
import SocketContext from "../../Utils/socket-context";

import { Trans } from "react-i18next";
import Pagination from "../../Utils/Pagination";
import UserEditOverlay from "../User/UserEditOverlay";
import History from "./SiteDetailComponents/History";
import Planning from "./SiteDetailComponents/Planning";
import Assets from "./SiteDetailComponents/Assets";
import General from "./SiteDetailComponents/General";

const mapStateToProps = state => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAuth: user => dispatch(actions.setAuthAction(user)),
    isLoading: e => dispatch(actions.startLoading(e)),
    doneLoading: () => dispatch(actions.doneLoading()),
    setFlash: f => dispatch(actions.setFlash(f)),
    closeFlash: f => dispatch(actions.closeFlash(f))
  };
};

const moment = require("moment");

class SiteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site: {}
    };

    // props.isLoading({
    // 	message: "Loading site information",
    // 	animated: true
    // });
  }

  componentDidMount() {
    api
      .get("/api/sites/detail/" + this.props.match.params.id)
      .then(response => {
        if (response.data.success) {
          this.setState({
            site: response.data.data
          });
        }
      });
  }

  render() {
    const baseUrl = "/delivery/sites/detail/" + this.props.match.params.id;
     
    return (
      <div>
        <div className={"container-header"}>Zone</div>

        <div className={"clear"} />

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              <ul className={"side-bar-menu"}>
                <li>
                  <Link to={baseUrl}>Informations générales</Link>
                </li>
                <li>
                  <Link to={baseUrl + "/history"}>Historique</Link>
                </li>
                <li>
                  <Link to={baseUrl + "/assets"}>Assets</Link>
                </li>
                <li>
                  <Link to={baseUrl + "/planning"}>Planning</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className={"col-md-10"}>
            <Switch>
              <Route exact path="/delivery/sites/detail/:id/history" component={History} />
              <Route exact path="/delivery/sites/detail/:id/assets" component={Assets} />
              <Route exact path="/delivery/sites/detail/:id/planning" component={Planning} />
              <Route
                exact
                path="/delivery/sites/detail/:id"
                component={() => <General site={this.state.site} />}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteDetail);
