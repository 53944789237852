import React, { Component } from "react";
import debounce from "lodash/debounce";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AddressList from "../../Components/Addresses/AddressList";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import { Trans } from "react-i18next";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faFileExport, faPlus, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import SitesExport from "../../Utils/SitesExport";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import SiteHistoryOverlay from "../../Components/Addresses/SiteList/SiteHistoryOverlay";
import SiteImportOverlay from "../../Components/Addresses/SiteList/SiteImportOverlay";
import FilterElement from "../../Utils/Filter/FilterElement";
import FiltersWrap from "../../Utils/Filter/FiltersWrap";
import Restricted from "../../Utils/Restricted";


const remote = Constants.apiURL; // process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'http://localhost/witrack-rollout-backend/api/public/';

const mapStateToProps = (state) => {
  return {
    auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: (e) => dispatch(setAuthAction(e)),
  };
}

class SitesListContainer extends Component {

  constructor(props) {

    super(props);

    this.props.isLoading({
      message: "Chargement des zones",
      animate: true,
    });

    this.state = {
      showFilters: false,
      sites_file_ready: false,
      wilayas: [],
      managers: [],
      cluster_managers: [],
      sites: [],
      agents: [],
      status: [],
      types: [],
      rows: [],
      current: 1,
      pages: 1,
      limit: 15,
      pm_start: "",
      pm_end: "",
      filters: {},
      power_type: [],
      marque_ac: [],
      bss_equip: [],
      marque_battery: [],
      showOverlay: true,
      showExportOverlay: false,
      showHistoryOverlay: false,
      selected_site: {},
      activeOverlay: null,
    };

    this.loadSites = debounce(this.loadSites.bind(this), 300);
    this.updateFilter = this.updateFilter.bind(this);

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadSites: this.loadSites,
      selectSite: this.selectSite.bind(this),
    };

    this.getCrits = this.getCrits.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }

  handleEscape = (e) => {

    if (e.keyCode === 27) {
      this.setState({ activeOverlay: null });
    }
  };

  componentWillUnmount() {

    document.removeEventListener("keyup", this.handleEscape);
  }

  changeLimit = (e) => {

    if (window.ga)
      window.ga("send", "event", "TaskList", "changeLimit", e.value);

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1,
      },
      () => this.loadSites()
    );
  };

  updateFilter(event, name) {

    let that = this;
    const value = event.target ? event.target.value : event.value;

    if (window.ga)
      window.ga("send", "event", "SitesList", "updateFilter", name);

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      function() {
        that.props.isLoading();
        that.loadSites(1);
      }
    );
  }

  getCrits = () => {

    const crits = buildFilters(this.state.filters);
    const action = `${remote}/api/sites/fetch/filter?export=true&excel=true&limit=-1&token=${this.props.user.token}&${crits}`;
    return action;
  };

  componentDidMount() {

    document.addEventListener("keyup", this.handleEscape);
    this.loadSites();
  }

  loadSites(p = this.state.current) {

    const crits = buildFilters(this.state.filters);
    api.get(`/api/sites/fetch/filter?page=${p}&limit=${this.state.limit}&${crits}`)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            sites: response.data.sites,
            pages: response.data.pages,
            total: response.data.total,
          });
        } else {
          if (response.data.errcode === 101) {
            this.props.setAuth(false);
          }
        }
        this.props.doneLoading();
      });
  }

  selectPage = (p) => {

    if (window.ga) window.ga("send", "event", "SitesList", "selectPage", p);

    this.props.isLoading();
    this.setState({ current: p }, () => this.loadSites(p));
  };

  importClick = (e) => {

    if (this.state.sites_file_ready) {
      console.log("submitting");
    } else {
      e.preventDefault();
      this.setState({ sites_file_ready: true });
    }
  };

  submitBulkSites = (e) => {

    e.preventDefault();
    const form = new FormData(e.target);

    api.post("/api/sites/bulk", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
      });
  };

  handelFileChange = (e) => {

    if (e.target.value) {
      this.setState({ sites_file_ready: true });
    } else {
      this.setState({ sites_file_ready: false });
    }
    console.log(e.target);
  };

  toggleOverlay = () => this.setState({ showOverlay: !this.state.showOverlay });

  toggleFilters = (e) => {
    if (window.ga)
      window.ga(
        "send",
        "event",
        "SitesList",
        "toggleFilters",
        this.state.showFilters ? "hide" : "show"
      );

    e.preventDefault();
    this.setState({ showFilters: !this.state.showFilters, });
  };

  selectSite = (site) => {

    this.setState({
      selected_site: site,
      activeOverlay: "history",
      showHistoryOverlay: true,
    });
  };

  toggleHistoryOverlay = () => this.setState({ activeOverlay: "history" });

  exportPlanning = (e) => {

    e.preventDefault();

    const crits = buildFilters(this.state.filters);
    const action =
      remote +
      "/api/sites/fetch/filter?export=true&planning=true&limit=-1&token=" +
      this.props.user.token +
      "&" +
      crits;

    window.location = action;
    //e.target.submit();
  };

  render() {

    return (
      <React.Fragment>
        {!this.props.auth ? <Redirect to="/login" /> : ""}

        {this.state.activeOverlay === "history" && (
          <SiteHistoryOverlay
            site={this.state.selected_site}
            open={true}
            toggleOverlay={() => this.setState({ activeOverlay: null })}
          />
        )}

        {this.state.activeOverlay === "import" && (
          <SiteImportOverlay
            close={() => this.setState({ activeOverlay: null })}
            open={true}
          />
        )}

        {this.state.activeOverlay === "export" && (
          <SitesExport
            closeModal={() => this.setState({ activeOverlay: null })}
            open={true}
            getAction={this.getCrits}
          />
        )}

        <div className={"container-header"}>
          zones
          <div className={"float-right"}>
            <Restricted scope={"pm"} permission={"edit_agent_site"}>
              <button
                type="button"
                onClick={() => this.setState({ activeOverlay: "import" })}
                className={"btn btn-sm ml-2 btn-primary"}
              >
                <FontAwesomeIcon icon={faFileExcel} className={"mr-2"} />
                Importer
              </button>
            </Restricted>

            <Restricted scope={"pm"} permission={"read_site"}>
              <button
                type="button"
                className={"btn btn-sm btn-primary ml-2"}
                onClick={() => this.setState({ activeOverlay: "export" })}
              >
                <FontAwesomeIcon icon={faFileExport} className={"mr-2"} />
                Exporter la liste
              </button>
            </Restricted>


            <Restricted scope={"pm"} permission={"export_site_planning"}>
              <button
                onClick={this.exportPlanning}
                type="button"
                className={"btn btn-sm btn-primary ml-2"}
              >
                <FontAwesomeIcon icon={faFileExport} className={"mr-2"} />
                Exporter Planning
              </button>
            </Restricted>
          </div>
        </div>

        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
              {this.props.user &&
                (this.props.user.role === "cluster_manager" ||
                  this.props.user.role === "admin") && (
                  <>
                  <Restricted scope={"pm"} permission={"add_site"}>
                    <Link
                      to={"/delivery/sites/new"}
                      className={"btn btn-primary btn-block mb-2"}
                    >
                      <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                      <Trans i18nKey="label_new_site" />
                    </Link>
                  </Restricted>
                  </>
                )}

              <h4>Recherche</h4>

              <FiltersWrap update={this.updateFilter} session={this.props.user}>
                <FilterElement label={"Zone name"} name={"site_name"} />
                <FilterElement label={"Code Zone"} name={"site_code"} />
                <FilterElement
                  type={"options"}
                  role={"admin"}
                  label={"Status"}
                  name={"site_status"}
                  options={this.props.filters.site_status}
                  defaultValue={{
                    label: "Active",
                    value: "active",
                  }}
                />

                <FilterElement
                  type={"options"}
                  label={"Type site"}
                  name={"cosite_type"}
                  options={this.props.filters.cosite_types}
                />

                <FilterElement
                  type={"date"}
                  label={"Date plan start"}
                  name={"plan_start"}
                  defaultValue={this.state.filters.plan_start}
                />

                <FilterElement
                  type={"date"}
                  label={"Date plan end"}
                  name={"plan_end"}
                  defaultValue={this.state.filters.plan_end}
                />

              {/*  <FilterElement
                  role={"admin"}
                  type={"options"}
                  label={"Cluster"}
                  name={"site_cluster"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.clusters,
                  ]}
                /> */}

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Wilaya"}
                  name={"site_wilaya"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.wilayas,
                  ]}
                />

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Site Owner"}
                  name={"site_owner"}
                  options={this.props.filters.site_owners}
                />

                <FilterElement
                  role={["admin", "cluster_manager"]}
                  type={"options"}
                  label={"Manager"}
                  name={"site_manager"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.managers,
                  ]}
                />

                <FilterElement
                  role={["admin", "cluster_manager", "manager"]}
                  type={"options"}
                  label={"Agent"}
                  name={"site_agent"}
                  options={[
                    { label: "Tous", value: "" },
                    ...this.props.filters.agents,
                  ]}
                />

                <FilterElement
                  type={"radio"}
                  name={"has_plan"}
                  options={[
                    { label: "Tous", value: "", default: true },
                    { label: "Non Planifié", value: false },
                    { label: "Planifié", value: true },
                  ]}
                />

                <FilterElement
                  type={"radio"}
                  name={"assigned"}
                  options={[
                    { label: "Tous", value: "", default: true },
                    { label: "Non Attribué", value: false },
                    { label: "Attribué", value: true },
                  ]}
                />

                {/* <FilterElement
                  type={"radio"}
                  name={"pmdone"}
                  options={[
                    { label: "Tous", value: "", default: true },
                    { label: "Delivery Not Done", value: false },
                    { label: "Delivery Done", value: true },
                  ]}
                /> */}
              </FiltersWrap>

          {/*    <div className={"vert"}>
                <div className={"mb-3"}>
                  <a
                    onClick={this.toggleFilters}
                    href="#"
                    className={"btn-block btn btn-primary"}
                  >
                    Recherche avancée
                    <FontAwesomeIcon
                      className={"ml-3"}
                      icon={faChevronCircleDown}
                    />
                  </a>
                </div>

                <div
                  className={
                    "advanced-filters" + (this.state.showFilters ? " open" : "")
                  }
                >
                  <div className={"form-group"}>
                    <label>Type de site</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.type_site}
                      placeholder={"Selectionnez..."}
                      onChange={(e) => this.updateFilter(e, "type")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Typology</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.typology_site}
                      placeholder={"Selectionnez..."}
                      onChange={(e) => this.updateFilter(e, "typology")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Support antenne</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.support_antenne}
                      placeholder={"Selectionez..."}
                      onChange={(e) => this.updateFilter(e, "antenne")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Type AC</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.type_ac}
                      placeholder={"Type AC"}
                      onChange={(e) => this.updateFilter(e, "type_ac")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Marque AC</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.marques_ac}
                      placeholder={"Marque AC"}
                      onChange={(e) => this.updateFilter(e, "marque_ac")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Type Power supply</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.marques_power}
                      placeholder={"Type PS"}
                      onChange={(e) => this.updateFilter(e, "power_type")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>Batterie</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.marques_batteries}
                      placeholder={"Marque batterie"}
                      onChange={(e) => this.updateFilter(e, "marque_batterie")}
                    />
                  </div>

                  <div className={"form-group"}>
                    <label>BSS</label>
                    <Select
                      classNamePrefix={"custom-select"}
                      options={this.props.filters.types_bss}
                      placeholder={"Equipement BSS"}
                      onChange={(e) => this.updateFilter(e, "bss_equip")}
                    />
                  </div>
                </div>
              </div>*/}
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> Zones trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <AddressList
              data={this.state}
              rows={this.state.rows}
              handlers={this.handlers}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const SitesListContainerConsumer = (props) => (
  <SocketContext.Consumer>
    {(socket) => <SitesListContainer {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(SitesListContainerConsumer);
