import React from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { formToObject } from "../../../Utils/Helpers";
import { useDispatch } from "react-redux";
import { setOverlay } from "../../../Redux/actions";

const AssignSiteOverlay = ({
  open,
  site,
  loadSites,
  close,
  type = "pm",
  ...props
}) => {

  const dispatch = useDispatch();
  
  const submitAssignForm = (e) => {
    e.preventDefault();

    const dispatchOverlay = e => dispatch(setOverlay(e));


    props.isLoading();

    let data = formToObject(e.target);

    data['selected_site'] = site.site_id;

    api.post(`/api/sites/change_owner/${site.site_id}/${type}/agent`, data).then((response) => {
      if (response.data.success) {

        dispatchOverlay({
					message: 'Site attribué avec succés',
					label: "success",
					button: {
						type: "close",
						label: "OK",
						link: "javascript:void(0)"
					}
        });


        loadSites();
        close();
      } else {
        props.setFlash({
          active: true,
          message: "Une erreur est survenue, veuillez contacter l'administrateur.",
          classes: "warning",
          style: {},
        });
      }

      props.doneLoading();
    });
  };

  return (
    <div className={open ? "ui-overlay open" : "ui-overlay"}>
      <div
        className={
          "ui-overlay-content status-overlay-text col-md-6 col-lg-4 mx-auto"
        }
      >
        <button
          onClick={close}
          className={"ui-overlay-close btn btn-danger btn-sm"}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h5 className="mb-4">Attribuer le site à un agent</h5>
        <form onSubmit={submitAssignForm}>
          
          <div className="form-group">
            
            <label className={"font-exp mb-2"}>Select agent</label>
            <Select
              classNamePrefix={"custom-select"}
              name={"site_owner"}
              defaultValue={{
                label: site.site_owner_agent, 
                value: site.site_owner_agent_id
              }}
              options={props.filters.agents}
              cacheOptions
              defaultOptions
              placeholder="Agent"
              className={"mb-3"}
            />

            <input
              type="submit"
              value="Assigner"
              className={"btn btn-primary mt-3 px-4 py-2 w-100"}
            />
          </div>

        </form>
        <div className="mt-3">
           <small className="text-muted">Agent will receive a notification.</small>
        </div>
      </div>
    </div>
  );
};

export default AssignSiteOverlay;
